import React, { Component } from 'react';
import { Container, Grid, Button, TransitionablePortal } from 'semantic-ui-react'
import '../../App.css';
import StartButton from '../StartButton/StartButton.jsx'
import Video3 from '../../video/04.mov'

class S1 extends Component {
    state = {
        // Subscribe Button:
        open: false
    }

    // Subscribe Button: Functions
    handleClick = () => this.setState((prevState) => ({ open: !prevState.open }))
    handleClose = () => this.setState({ open: false })

    render() {
        // Subscribe Button:
        const { open } = this.state
        return (
            <div className="s1">
                <Grid centered>
                    <Grid.Row>
                        <Grid.Column>
                            <Container text>
                                <h1 className="s1TitleSub">An online business presence through quality coding</h1>
                                <h3 className="s1">If you need a website, Ponder Code can build it. We use industry standard technologies to develop custom front-end, e-commerce, and situationally full-stack websites and applications to fit your specific business requirements.</h3>
                                <h3 className="s1">In addition to business website development, we offer web app prototyping and mvp (minimum viable product) development services. Don't let your ideas end as such. Ponder Code can help turn them into something usable and testable.</h3>
                                <br></br>
                                <br></br>
                                <StartButton />
                            </Container>
                            <br></br>
                            <br></br>
                            <Container text>
                                {/* <Image fluid centered src='https://c.tenor.com/vrNljoCTO70AAAAd/blade-runner-flying-cars.gif' /> */}
                                {/* <Image fluid centered src='https://c.tenor.com/_DOBjnGspYAAAAAM/code-coding.gif' /> */}
                                <video className="s1video" src={Video3} autoPlay type="video/mp4" loop muted/>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <br></br>
            </div>
        )
    }
}

export default S1
