import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Divider, Container, Radio, Grid, Image } from 'semantic-ui-react'
import '../../App.css';
import Main from '../../img/01.png'

const Activator = (props) => {

    // handleClick = () => this.setState((prevState) => ({ open: !prevState.open }))
    // handleClose = () => this.setState({ open: false })
    
    return (
        <div className='errorMenu'>
        <br></br>
            <Container>
            <Link to="/">
                <Image className='errorMenuImg' src={Main} />
            </Link>
            </Container>   
            <Divider></Divider>
        </div>
    )
}

export default Activator