import React, { Component } from 'react';
import { Segment, Container, Icon, Divider, Card, Grid, Column, Image, TransitionablePortal, Loader } from 'semantic-ui-react'
import '../../App.css';
import Tech from '../../img/tech_2.png'
import Tech2 from '../../img/tech2_2.png'
import Tech3 from '../../img/tech3_2.png'

const S2 = (props) => {

    return (
        <div className={props.s2}>
            <br></br>
            <br></br>
            
            {/* Title */}
            <Grid centered>
                <Grid.Row columns={1}>
                    <Container text>
                    <h2 className={props.s2TitleSub}>The technology stack that Ponder Code develops with</h2>
                    </Container>
                </Grid.Row>
            </Grid>
            <br></br>
            <br></br>

            <Container>
            <Grid>
                {/* <Grid.Column computer={7} tablet={10}> */}
                <Card.Group itemsPerRow={3} stackable>
                    
                    {/* Card 02 */}
                    <Card centered size='large' stackable>
                        <Image src={Tech} wrapped ui={false} />
                        <Card.Content>
                        <h1 className="s1TitleSub">Core Stack</h1>
                        <Card.Description size='huge'>
                            <Icon color='red' size='huge' name='html5' />
                            HTML5
                        </Card.Description>
                        <Card.Description size='huge'>
                            <Icon color='blue' size='huge' name='css3 alternate' />
                            CSS3
                        </Card.Description>
                        <Card.Description size='huge'>
                            <Icon color='yellow' size='huge' name='js' />
                            JavaScript
                        </Card.Description>
                        <Card.Description size='huge'>
                            <Icon color='blue' size='huge' name='react' />
                            React.js
                        </Card.Description>
                        </Card.Content>
                    </Card>   

                    {/* Card 01 */}
                    <Card centered size='large' stackable>
                        <Image src={Tech3} wrapped ui={false} />
                        <Card.Content>
                        <h1 className="s1TitleSub">E-Commerce</h1>
                        <Card.Description size='huge'>
                            <Icon color='black' size='huge' name='cart plus' />
                            {/* <img width='120' src='https://icon-library.com/images/django-icon/django-icon-7.jpg' /> */}
                            Shopify
                        </Card.Description>
                        </Card.Content>
                    </Card>

                    {/* Card 03 */}
                    <Card centered size='large' stackable>
                        <Image src={Tech2} wrapped ui={false} />
                        <Card.Content>
                        <h1 className="s1TitleSub">Data Stack</h1>
                        <Card.Description size='huge'>
                            <Icon color='blue' size='huge' name='python' />
                            {/* <img width='120' src='https://icon-library.com/images/django-icon/django-icon-7.jpg' /> */}
                            Python 3
                        </Card.Description>
                        <Card.Description size='huge'>
                            <Icon color='grey' size='huge' name='cogs' />
                            {/* <img width='120' src='https://icon-library.com/images/django-icon/django-icon-7.jpg' /> */}
                            Django | DRF
                        </Card.Description>
                        <Card.Description size='huge'>
                            <Icon color='black' size='huge' name='database' />
                            
                             {/* <img size='huge' src='https://wiki.postgresql.org/images/3/30/PostgreSQL_logo.3colors.120x120.png' /> */}
                            PostgreSQL
                        </Card.Description>
                        </Card.Content>
                    </Card>
                    
                </Card.Group>               
                {/* </Grid.Column> */}
            </Grid>
            </Container>

            <br></br>
            <br></br>
        </div>
    )
}

export default S2