import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { TransitionablePortal, Segment, Icon, Divider, Grid, Header } from 'semantic-ui-react'
import '../../App.css';
import The404Button from '../404Button/404Button.jsx'

class Footer extends Component {
    state = {
        value: 'code@pondermint.com',
        phone: 9085094034,
        copied: false,
        open: false,
        title: ''
    }

    handleClick = () => this.setState((prevState) => ({ open: !prevState.open, title: 'Email' }))
    handleClose = () => this.setState({ open: false })

    handleClick2 = () => this.setState((prevState) => ({ open: !prevState.open, title: 'Phone number' }))
    handleClose = () => this.setState({ open: false })

    render() {
        const { open } = this.state
        return (
            <div>
            <Divider inverted></Divider>
                <Grid centered columns={5}>
                    <Grid.Row>
                    <Grid.Column>
                        
                        </Grid.Column>
                        <Grid.Column>
                            <p className='footer'>© 2022 - 2024 Ponder Code | Ponder Enterprises LLC </p>
                        </Grid.Column>
                        <Grid.Column>
                            <a href='https://www.facebook.com/pondermint'>
                                <Icon inverted name='facebook official' size='big' />
                            </a>
                            <a href='https://www.linkedin.com/company/ponder-enterprises-llc/?viewAsMember=true'>
                                <Icon inverted name='linkedin' size='big' />
                            </a>
                            <CopyToClipboard text={this.state.value} onCopy={() => this.setState({copied: true})}>
                                <Icon inverted 
                                    name='mail' 
                                    size='big' 
                                    content={open ? 'Close Portal' : 'Open Portal'}
                                    negative={open}
                                    positive={!open}
                                    onClick={this.handleClick} />
                            </CopyToClipboard>
                            <CopyToClipboard text={this.state.phone} onCopy={() => this.setState({copied: true})}>
                                <Icon inverted 
                                    name='phone square' 
                                    size='big' 
                                    content={open ? 'Close Portal' : 'Open Portal'}
                                    negative={open}
                                    positive={!open}
                                    onClick={this.handleClick2} />
                            </CopyToClipboard>
                        </Grid.Column>
                        <Grid.Column>
                            <div>
                                <The404Button />
                            </div>
                        </Grid.Column>
                        <Grid.Column></Grid.Column>

                    </Grid.Row>
                </Grid>
                    <TransitionablePortal onClose={this.handleClose} open={open}>
                        <Segment
                            style={{ left: '5%', position: 'fixed', top: '80%', zIndex: 1000 }}
                        >
                            <Header>{this.state.title} copied to clipboard.</Header>
                        </Segment>
                    </TransitionablePortal>
            </div>
        )
    }
}

export default Footer