import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Divider, Menu, Radio, Grid, Image, Container } from 'semantic-ui-react'
import '../../App.css';
import Main from '../../img/01.png'

const Activator = (props) => {

    // handleClick = () => this.setState((prevState) => ({ open: !prevState.open }))
    // handleClose = () => this.setState({ open: false })
    
    return (
        <div className={props.mode}>
            <br></br>  
            <Container>
            <Grid centered>
                <Grid.Row>
                    <Grid.Column tablet={15} computer={4}> 
                        <Link to="/">
                        <img className="photo" src={Main} />
                        </Link>
                        {/* <p className={props.photo}>Ponder Code</p> */}
                    </Grid.Column>       
                    <Grid.Column stackable tablet={15} computer={10}>        
                        <Menu className={props.menu} fluid stackable secondary size='huge'>
                            {/* Services */}
                            {/* <Menu.Item
                            className={props.nav}
                            name='about'
                            as={Link}
                            to="/about"
                            active={props.activeItem === 'about'}
                            // onClick={this.handleClick}
                            />
                            <Menu.Item
                            className={props.nav}
                            name='options'
                            as={Link}
                            to="/options"
                            active={props.activeItem === 'options'}
                            // onClick={this.handleClick}
                            /> */}
                            <Menu.Item
                            className={props.nav}
                            name='portfolio'
                            as={HashLink}
                            to="/#portfolio"
                            active={props.activeItem === 'portfolio'}
                            // onClick={this.handleClick}
                            />
                            <Menu.Item
                            className={props.nav}
                            name='contact'
                            as={HashLink}
                            to="/#contact"
                            active={props.activeItem === 'contact'}
                            // onClick={props.handleClick}
                            />
                            <Menu.Item>
                                <Radio 
                                toggle 
                                onClick={props.toggleMode}
                                />  
                                <Image  
                                className={props.iconSize} 
                                src={props.icon} 
                                />   
                            </Menu.Item>          
                        </Menu>
                    </Grid.Column>
                    {/* To keep the menu from edge. */}
                    <Grid.Column>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </Container> 
            <Divider></Divider>
        </div>
    )
}

export default Activator