import React, { Component } from 'react';
import { Form, Grid } from 'semantic-ui-react'
import '../../App.css';

const Contact = (props) => {

    return (
        <div id='contact' className={props.contact}>
            <br></br>
            <br></br>
            <Grid centered>
            <h1 className={props.s1TitleSub}>Ready to begin? Contact us.</h1>
            </Grid>
            {/* Title */}
            <Grid centered>
                <h1 className={props.s1TitleSub}>Submit this form or contact us by email or phone.</h1>

                {/* Contact Form */}
                <Form
                    action="https://docs.google.com/forms/u/2/d/e/1FAIpQLSeshlD0Co1wMH5UKcQ5Ghv6gHA3ARTTcRt1vJpMQ9GUldKcAQ/formResponse" method='POST' target="_blank" onSubmit={props.refreshPage}
                >
                    <Form.Input 
                        fluid label='Email' 
                        placeholder='Email' 
                        name="entry.1163219132"                        
                        type='email'
                    />    
                    <Form.Input 
                        fluid label='Name' 
                        placeholder='Name' 
                        name="entry.148381476"
                    />
                    {/* <Form.Input 
                        fluid label='Last Name' 
                        placeholder='Last Name'
                        name="entry.148381476"                    
                    /> */}
                    <Form.Input 
                        fluid label='Company Name' 
                        placeholder='Company Name' 
                        name="entry.1892080100"
                    />
                    <Form.TextArea  
                        fluid label='Message' 
                        placeholder='Write a message here.' 
                        name="entry.1697592864"                        
                        type='email'
                    />
                    <Form.Button content='Submit'/>
                </Form>
            </Grid>

            <br></br>
            <br></br>
        </div>
    )
}

export default Contact