import React, { Component } from 'react';
import { Card, Grid, Reveal } from 'semantic-ui-react'
import '../../App.css';
import Test01 from './img/nu.png';
import Test02 from './img/srs.png';
import Test03 from './img/pm.png';
import Test04 from './img/04_2.png';
import Test05 from './img/cs.png';
import Test06 from './img/dice.png';
import Test07 from './img/sd.png';
import Test08 from './img/dl.png';
import Test00 from './img/pe.png';
import Test10 from './img/bt.png';

const items1 = [
    {
        image: Test10,
        header: 'Battle Thesis: Who Would Win in a Fight?',
        meta: 'www.battlethesis.com',
        href: 'https://battlethesis.com/'
    },
    {
        image: Test05,
        header: 'Code Seonbi: Programming References and Bug Tracker',
        meta: 'www.codeseonbi.com',
        href: 'https://code-seonbi-v2.herokuapp.com/',
    },
    {
        image: Test06,
        header: 'DiceDex: Tabletop Game Group Database ',
        meta: 'www.dicedex.io',
        href: 'https://www.dicedex.io/',
    },
]

const items2 = [
    {
        image: Test08,
        header: 'Dual-Lists 2: Simple Shopping List Android App',
        meta: 'Google Play Store',
        href: 'https://play.google.com/store/apps/details?id=com.ak89mkii.AwesomeProject'
    },
    {
        image: Test01,
        header: 'Nu Game Store: Tabletop and Video Games',
        meta: 'www.repbucket.com (temporary)',
        href: 'https://www.repbucket.com/',
    },
    {
        image: Test00,
        header: 'Ponder Enterprises Store',
        meta: 'www.ponderenterprises.com',
        href: 'https://www.pondermint.com',
    },
]

const items3 = [
    {
        image: Test03,
        header: 'Ponder Mint',
        meta: 'www.pondermint.com',
        href: 'https://www.pondermint.com/',
    },
    {
        image: Test02,
        header: 'Sam Rose Salon',
        meta: 'www.samrosesalon.surge.sh',
        href: 'https://samrosesalon.surge.sh/',
    },
    {
        image: Test07,
        header: 'Sholodex: The Show Lowdown Index',
        meta: 'www.sholodex.com',
        href: 'https://www.sholodex.com/',
    },
]

const items4 = [
    {
        image: Test04,
        header: "Developer's Portfolio",
        meta: 'www.aikasem.com',
        href: 'https://www.aikasem.com/',
    },
]

// // Possible Functionality:
// function changeBackground(e) {
//     e.target.style.visibility = 'hidden'
// }

// onMouseOver={changeBackground} 

class Portfolio extends Component {
    state = {

    }

    render() {
        return (
            <div id='portfolio' className="portfolio">
                <br></br>
                <br></br>
                <Grid stackable centered itemsPerRow={1}>
                <Grid.Row>
                    <h2 className="s2TitleSub">Ponder Code Portfolio</h2>
                </Grid.Row>
                <Grid.Row>
                    <Card.Group centered items={items1} />
                </Grid.Row>
                <Grid.Row><p className="monk">|</p></Grid.Row>
                <Grid.Row>
                    <Card.Group centered items={items2} />
                </Grid.Row>
                <Grid.Row><p className="monk">|</p></Grid.Row>
                <Grid.Row>
                    <Card.Group centered items={items3} />
                </Grid.Row>
                <Grid.Row><p className="monk">|</p></Grid.Row>
                <Grid.Row>
                    <Card.Group centered items={items4} />
                </Grid.Row>
                <Grid.Row><p className="monk">|</p></Grid.Row>
                </Grid>

                <br></br>
                <br></br>
            </div>
        )
    }
}

export default Portfolio