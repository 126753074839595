import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

function Temp() {
  const [open, setOpen] = React.useState(true)

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}

    >
      <Header icon>
        <Icon name='code' />
        Site Under Construction
      </Header>
      <Modal.Content>
        <p>
          Ponder Code is functional, but some pages and features may still be incomplete.
        </p>
        <Button color='' inverted onClick={() => setOpen(false)}>
          <Icon name='remove' /> Close
        </Button>
      </Modal.Content>
    </Modal>
  )
}

export default Temp