import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Image, Grid, Button} from 'semantic-ui-react'
import '../../App.css';
import S1 from '../../components/S1/S1.jsx'
import S2 from '../../components/S2/S2.jsx'
import Contact from '../../components/Contact/Contact.jsx'
import Portfolio from '../../components/Portfolio/Portfolio.jsx'
import Menu from '../../components/Menu/Menu.jsx'
import Footer from '../../components/Footer/Footer.jsx'
import sun from '../../img/sun.png'
import moon from '../../img/moon.png'

class Home extends Component {
    state = {
        check: 0,
        open: false,
        mode: 'light',
        photo: 'photo',
        nav: 'nav',
        menu:  'ui menu',
        contact: 'contact',
        s1TitleSub: 's1TitleSub',
        s2: 's2',
        s2TitleSub: 's2TitleSub',
        icon: sun,
        iconSize: 'iconSize'
    }

    toggleMode = () => {
        if (this.state.mode == 'light') {
            // this.handleFormSubmit();
            this.setState({
                mode: 'dark',
                photo: 'photoDark',
                nav: 'navDark',
                menu: 'ui inverted menu',
                contact: 'contactDark',
                s1TitleSub: 's1TitleSubDark',
                s2: 's2Dark',
                s2TitleSub: 's2TitleSubDark',
                icon: moon,
            })
            this.handleFormSubmitDark();
        } else if (this.state.mode == 'dark') {
            // this.handleFormSubmit();
            this.setState({
                mode: 'light',
                photo: 'photo',
                nav: 'nav',
                menu: 'ui menu',
                contact: 'contact',
                s1TitleSub: 's1TitleSub',
                s2: 's2',
                s2TitleSub: 's2TitleSub',
                icon: sun,
            })
            this.handleFormSubmitLight();
        }
    }

    refreshPage = () => {
        setTimeout(function () { window.location.reload(); }, 10)
    }

    // Save mode: light in local Storage:
    handleFormSubmitLight = () => {
        localStorage.setItem('mode', 'light');
        localStorage.setItem('photo', 'photo');
        localStorage.setItem('nav', 'nav');
        localStorage.setItem('menu', 'ui menu');
        localStorage.setItem('contact', 'contact');
        localStorage.setItem('s1TitleSub', 's1TitleSub');
        localStorage.setItem('s2', 's2');
        localStorage.setItem('s2TitleSub', 's2TitleSub');
        localStorage.setItem('icon', sun);
    };

    // Save mode: dark in local Storage:
    handleFormSubmitDark = () => {
        localStorage.setItem('check', 1);
        localStorage.setItem('mode', 'dark');
        localStorage.setItem('photo', 'photoDark');
        localStorage.setItem('nav', 'navDark');
        localStorage.setItem('menu', 'ui inverted menu');
        localStorage.setItem('contact', 'contactDark');
        localStorage.setItem('s1TitleSub', 's1TitleSubDark');
        localStorage.setItem('s2', 's2Dark');
        localStorage.setItem('s2TitleSub', 's2TitleSubDark');
        localStorage.setItem('icon', moon);
    };

    // Retreive mode in localStorage:
    componentDidMount() {
        const check = localStorage.getItem('check');
        this.setState({ check });
        console.log({check})

        if (check == 1) {
        // console.log("halo")

        const mode = localStorage.getItem('mode');
        this.setState({ mode });
        const photo = localStorage.getItem('photo');
        this.setState({ photo });
        const nav = localStorage.getItem('nav');
        this.setState({ nav });
        const menu = localStorage.getItem('menu');
        this.setState({ menu });
        const contact = localStorage.getItem('contact');
        this.setState({ contact });
        const s1TitleSub = localStorage.getItem('s1TitleSub');
        this.setState({ s1TitleSub });
        const s2 = localStorage.getItem('s2');
        this.setState({ s2 });
        const s2TitleSub = localStorage.getItem('s2TitleSub');
        this.setState({ s2TitleSub });
        const icon = localStorage.getItem('icon');
        this.setState({ icon });

        }
      }

    render() {

        return (
            <div>
                <Menu
                    open={this.state.false}
                    mode={this.state.mode}
                    toggleMode={this.toggleMode}
                    photo={this.state.photo}
                    nav={this.state.nav}
                    menu={this.state.menu}
                    icon={this.state.icon}
                    iconSize={this.state.iconSize}
                />
                <br></br>
                <br></br>
                <Grid centered>
                    <Grid.Row>
                        <div>
                            <h1 className="title">Ponder Code</h1>
                        </div>
                    </Grid.Row>
                    <Grid.Row>
                        <div>
                            <h2 className="titleSub">Minimalist Custom Web Apps</h2>
                        </div>
                    </Grid.Row>
                </Grid>
                <br></br>
                <br></br>
                <S1 />
                <br></br>
                <br></br>
                <S2 
                    s2={this.state.s2}
                    s2TitleSub={this.state.s2TitleSub}
                />
                <Portfolio />
                <br></br>
                <br></br>
                <Contact 
                    contact={this.state.contact}
                    s1TitleSub={this.state.s1TitleSub}
                    refreshPage={this.refreshPage}
                />
                <Footer />
            </div>
        )
    }
}

export default Home