import React, { Component } from 'react';
import { Segment, Grid, Icon, Item } from 'semantic-ui-react'
import '../../App.css';
import ErrorMenu from '../../components/ErrorMenu/ErrorMenu.jsx'
import Footer from '../../components/Footer/Footer.jsx'
import sun from '../../img/sun.png'
import moon from '../../img/moon.png'

class Error404 extends Component {
    state = {
        open: false,
        mode: 'light',
        photo: 'photo',
        nav: 'nav',
        menu:  'ui menu',
        contact: 'contact',
        s1TitleSub: 's1TitleSub',
        s2: 's2',
        s2TitleSub: 's2TitleSub',
        icon: sun,
        iconSize: 'iconSize'
    }
  
    toggleMode = () => {
        if (this.state.mode == 'light') {
            this.setState({
                mode: 'dark',
                photo: 'photoDark',
                nav: 'navDark',
                menu: 'ui inverted menu',
                contact: 'contactDark',
                s1TitleSub: 's1TitleSubDark',
                s2: 's2Dark',
                s2TitleSub: 's2TitleSubDark',
                icon: moon,
            })
        } else if (this.state.mode == 'dark') {
            this.setState({
                mode: 'light',
                photo: 'photo',
                nav: 'nav',
                menu: 'ui menu',
                contact: 'contact',
                s1TitleSub: 's1TitleSub',
                s2: 's2',
                s2TitleSub: 's2TitleSub',
                icon: sun,
            })
        }
    }
    
    render() {
        return (
            <div>
                <ErrorMenu />
                <br></br>
                <br></br>
                <Grid centered>
                    <Grid.Row>
                        <h1 className="title">404: Page Not Found</h1>
                    </Grid.Row>
                    <Grid.Row>
                        <h2 className="titleSub">Fireball (Left Mouse Click) | Movement (A, S, D) |  Jump (Spacebar)</h2>
                    </Grid.Row>
                </Grid>
                <br></br>
                <br></br>
                <Grid centered>
                <iframe frameborder="0" src="https://itch.io/embed-upload/4416194?color=333333" allowfullscreen="" width="1000" height="720"><a href="https://a-i-chasm-games.itch.io/bug-buster">Play Bug Buster on itch.io</a></iframe>                
                </Grid>
                <br></br>
                <br></br>   
                <Footer />     
            </div>
        )
    }
}

export default Error404;